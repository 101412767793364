/* eslint-disable no-sequences */
import React, { useState } from 'react';
import './App.css';
import axios from 'axios';
import logo from './img/logo_white.png';
import logoBlue from './img/logo_blue.png';

export default function App() {
    let form = [
        {
            title: 'Company Name',
            key: 'company',
            placeholder: 'Your company',
            style: 'full',
            type: 'text',
            error: 'Must supply a company name',
            validator: 'string_longer_than_3',
            valid: true,
        },
        {
            title: 'First Name',
            key: 'first_name',
            placeholder: 'Your first name',
            type: 'text',
            style: 'half',
            error: 'Must supply a first name',
            validator: 'string_longer_than_3',
            valid: true,
        },
        {
            title: 'Last Name',
            key: 'last_name',
            placeholder: 'Your last name',
            type: 'text',
            style: 'half',
            error: 'Must supply a last name',
            validator: 'string_longer_than_3',
            valid: true,
        },
        {
            title: 'Phone Number',
            key: 'phone',
            placeholder: '(xxx) xxx-xxxx',
            type: 'tel',
            style: 'full',
            error: 'Must supply a valid phone number',
            validator: 'valid_phone_number',
            valid: true,
        },
        {
            title: 'Estimated Device count range',
            key: 'device_count',
            placeholder: 'Estimate xIOT device count',
            type: 'select',
            style: 'full',
            options: ['500', '1000', '5k', '10k', '20k', '20K+'],
            error: 'Please supply and approximate device count',
            validator: 'select_not_null',
            valid: true,
        },
        {
            title: 'Email',
            key: 'email',
            placeholder: 'Your email, ex john@doe.com',
            type: 'email',
            style: 'full',
            error: 'Must supply a valid email address',
            validator: 'valid_email',
            valid: true,
        },
    ];
    // Form Variables
    const [data, setData] = useState({});
    const [error, setError] = useState(null);
    const [confirmation, setConfirmation] = useState(false);

    const [valid, setValid] = useState([
        { company: true, first_name: true, last_name: true, phone: true, device_count: true, email: true },
    ]);
    const [formReady, setFormReady] = useState(false);
    const checkFormReady = () => {
        let v = true;
        Object.values(valid).forEach(x => {
            if (x === false) {
                v = false;
            }
        });

        setFormReady(v);
    };

    const mail = /^([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;

    const validateForm = (key, validator, value) => {
        switch (validator) {
            case 'string_longer_than_3':
                setValid({ ...valid, [key]: value.length >= 1 });
                break;
            case 'valid_phone_number':
                setValid({ ...valid, [key]: value !== '' ? value.match(/\d/g).length >= 10 : false });
                break;
            case 'select_not_null':
                setValid({ ...valid, [key]: value !== 'Select a range' });
                break;
            case 'valid_email':
                setValid({ ...valid, [key]: mail.test(value) });
                break;

            default:
                return false;
        }
        checkFormReady();
    };

    async function postData() {
        setError(null);
        const url = `https://${window.location.host}/api/trial`;

        const res = await axios({
            url,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            data,
        }).catch(err => {
            console.log('err: ', err?.response?.data);
            return { success: false, message: err.message };
        });

        if (res?.success === false) {
            setError(res.message);
            return;
        }

        if (res?.data?.message === 'ayo') {
            setConfirmation(true);
            return;
        }

        setError(null);
        return;
    }

    const featureList = [
        'IoT Asset Discovery',
        'IoT Risk Assessment',
        'IoT Credential & Device Hardening',
        'IoT Remediation & Patch Management',
        'IoT Monitoring & Management',
    ];

    return (
        <div className="App">
            <div className="FormPageLeft">
                <div className="FormPageLeftHeader">
                    <img alt="Phosphorus Logo" src={logo} width="180px" />
                </div>
                <div className="TrialHeroText">
                    Go beyond discovery to full <span className="orange-x">x</span>IoT visibility, management, and
                    remediation with the Phosphorus Unified&nbsp;
                    <span className="orange-x">x</span>IoT Security Management Platform.
                </div>
                <div className="TrialSidebarList">
                    {featureList.map(feature => (
                        <div className="TrialSidebarListEl">
                            <div className="CheckboxContainer">
                                <svg
                                    width="28"
                                    height="28"
                                    viewBox="0 0 28 28"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M8.83334 13.8334L14.7083 7.95842L13.5417 6.79175L8.83334 11.5001L6.45834 9.12508L5.29167 10.2917L8.83334 13.8334ZM10 18.3334C8.84723 18.3334 7.76389 18.1147 6.75001 17.6772C5.73612 17.2397 4.85417 16.6459 4.10417 15.8959C3.35417 15.1459 2.76042 14.264 2.32292 13.2501C1.88542 12.2362 1.66667 11.1529 1.66667 10.0001C1.66667 8.8473 1.88542 7.76397 2.32292 6.75008C2.76042 5.73619 3.35417 4.85425 4.10417 4.10425C4.85417 3.35425 5.73612 2.7605 6.75001 2.323C7.76389 1.8855 8.84723 1.66675 10 1.66675C11.1528 1.66675 12.2361 1.8855 13.25 2.323C14.2639 2.7605 15.1458 3.35425 15.8958 4.10425C16.6458 4.85425 17.2396 5.73619 17.6771 6.75008C18.1146 7.76397 18.3333 8.8473 18.3333 10.0001C18.3333 11.1529 18.1146 12.2362 17.6771 13.2501C17.2396 14.264 16.6458 15.1459 15.8958 15.8959C15.1458 16.6459 14.2639 17.2397 13.25 17.6772C12.2361 18.1147 11.1528 18.3334 10 18.3334ZM10 16.6667C11.8611 16.6667 13.4375 16.0209 14.7292 14.7292C16.0208 13.4376 16.6667 11.8612 16.6667 10.0001C16.6667 8.13897 16.0208 6.56258 14.7292 5.27092C13.4375 3.97925 11.8611 3.33341 10 3.33341C8.13889 3.33341 6.56251 3.97925 5.27084 5.27092C3.97917 6.56258 3.33334 8.13897 3.33334 10.0001C3.33334 11.8612 3.97917 13.4376 5.27084 14.7292C6.56251 16.0209 8.13889 16.6667 10 16.6667Z"
                                        fill="#0ff"
                                    />
                                </svg>
                            </div>
                            <div className="CheckboxText">
                                {/* Note: revisit this if we want a feature sans-orange-x. */}
                                <span class="orange-x">x</span>
                                {feature}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="FormPageRight">
                <div className="blueLogo">
                    <img alt="logo2" src={logoBlue} width="280px" />
                </div>
                <div className="FormPageRightForm">
                    {!confirmation && (
                        <div>
                            <div className="FormPageRightFormTitle">
                                Request your <span style={{ fontWeight: 'bold' }}>Free Trial</span>{' '}
                            </div>
                            <div className="form-grid">
                                {form.map((x, index) => (
                                    <div key={x.title} className={x.style === 'full' ? 'form-item' : 'form-item-half'}>
                                        <div className="form-label">{x.title}*</div>
                                        {x.type !== 'select' && (
                                            <input
                                                className={valid[x.key] === false ? 'input_invalid' : 'input_valid'}
                                                tabIndex={index + 1}
                                                type={x.type || 'text'}
                                                // eslint-disable-next-line no-sequences
                                                onFocus={e => (
                                                    (form[index].valid = true),
                                                    setValid({ ...valid, [x.key]: true }),
                                                    checkFormReady()
                                                )}
                                                pattern={x.pattern ? x.pattern : null}
                                                value={data[x.key || '']}
                                                onChange={e => setData({ ...data, [x.key]: e.target.value })}
                                                placeholder={x.placeholder}
                                                // eslint-disable-next-line no-sequences
                                                onFocusCapture={e => (form[index].valid = true)}
                                                onBlur={e => validateForm(x.key, x.validator, e.target.value)}
                                            />
                                        )}
                                        {x.type === 'select' && (
                                            <select
                                                className="input_valid"
                                                tabIndex={index + 1}
                                                onBlur={e => validateForm(x.key, x.validator, e.target.value)}
                                                onFocus={e => (
                                                    (form[index].valid = true),
                                                    setValid({ ...valid, [x.key]: true }),
                                                    checkFormReady()
                                                )}
                                                onChange={e => setData({ ...data, [x.key]: e.target.value })}
                                            >
                                                <option value={null}>Select a range</option>
                                                {x.options.map(o => {
                                                    return <option value={o}>{o}</option>;
                                                })}
                                            </select>
                                        )}
                                        {valid[x.key] === false && <div className="error-label">{x.error}</div>}
                                    </div>
                                ))}
                            </div>

                            {error && <div style={{ marginTop: '15px', color: 'red', fontWeight: 500 }}>{error}</div>}

                            <button
                                tabIndex={form?.length + 1}
                                disabled={!formReady}
                                className="submit-btn"
                                onClick={() => postData()}
                            >
                                Request Free Trial
                            </button>
                        </div>
                    )}
                    {confirmation && (
                        <div className="success_message">
                            <div className="FormPageRightFormTitle">Thanks! Your trial request has been received</div>
                            <div className="">
                                You’re now one step closer to gaining full visibility into your enterprise xIoT. You’ll
                                hear from one of our account representatives very soon.
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="CopyrightFooter">
                Copyright © Phosphorus Cybersecurity {new Date().getFullYear()}. All Rights Reserved.
            </div>
        </div>
    );
}
